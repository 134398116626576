document.addEventListener("DOMContentLoaded", function() {
	//@ts-ignore
	let countryList = ezagwpsupport.countries;
	//@ts-ignore
	let countryTitle = ezagwpsupport.title;
	//@ts-ignore
	let cookiePreferenceTitle = ezagwpsupport.cookiePreferenceTitle;
	//@ts-ignore
	let cookieAcceptBtn = ezagwpsupport.cookieAcceptBtn;
	//@ts-ignore
	let cookieCustomBtn = ezagwpsupport.cookieCustomConsent;
	//@ts-ignore
	let countrySelectionJustification = ezagwpsupport.justification;

	let countrySelection = `
		<div class="mx-auto flex flex-col px-5 md:px-0 items-center md:items-start md:justify-between md:flex-row" style="max-width: 1024px;">
			<div class="flex flex-col items-start space-y-4">
            	<span class="text-left flex-grow-0 text-c_md font-semibold pt-3 md:pt-0 w-full text-center md:text-left">${countryTitle}</span>
            	<span class="text-left flex-grow-0 text-c_md pb-5 md:pb-0 md:pt-0 w-full text-center md:text-left">${countrySelectionJustification}</span>
            </div>
            <div class="flex-grow-0 relative float-right">
                <div class="py-3.5 leading-6 w-96 flex items-center justify-center rounded-5 bg-gray-c_bright6 cursor-pointer" id="lang_selector">
                    <p class="text-c_sm font-semibold" id="current_country">${countryTitle}</p>
                    <img id="lang_selector_chevron" class="absolute h-3 right-4 top-c_xs24 transform" src="/wp-content/plugins/ezagwpsupport/icons/arrow_down_gray.svg" alt="">
                </div>
                <div class="hidden absolute z-20 w-full overflow-hidden rounded-5-b">
                    <div id="options" class="w-full rounded-5-b bg-gray-c_bright2" style="max-height: 233px;overflow: scroll;">
                    </div>
                </div>
            </div>
        </div>
	`;

	let bannerPath = `//div[text()[contains(., '${cookiePreferenceTitle}')]]`
	let acceptPath = `//span[contains(., '${cookieAcceptBtn}')]`

	if(!document.cookie.includes("real_cookie_banner-blog")) {
		let container = document.createElement('div');
		container.classList.add("w-full", "bg-white", "py-4", "shadow", "relative");
		container.innerHTML = countrySelection;

		let ipBasedCountry: String;
		let cookies = document.cookie.split(';');
		cookies.map(cookie => {
			if(cookie.trim().startsWith('EZAG-Country='))
				ipBasedCountry = cookie.split("EZAG-Country=")[1];
		});


		function waitUntilBanner(){
			if (document.evaluate(bannerPath, document, null, XPathResult.ANY_TYPE, null ).iterateNext() == null){
				setTimeout(waitUntilBanner,50);
			} else {

				let add_country_cookie = () => {
						const date = new Date();
						date.setTime(date.getTime() + (365*24*60*60*1000));
						let expires = "expires="+ date.toUTCString();
						//@ts-ignore
						const selectedValue = document.querySelector("#lang_selector").innerText;
						countryList.map((country: any) => {
							if(selectedValue === country.name){
								document.cookie = "EZAG-Selected-Country" + "=" + country.slug.toUpperCase() + ";" + expires + ";path=/;SameSite=Strict";
							}
						});
				}

				//@ts-ignore
				document.evaluate(bannerPath, document, null, XPathResult.ANY_TYPE, null ).iterateNext().parentElement.parentElement.parentElement.prepend(container);
				document.evaluate(acceptPath, document, null, XPathResult.ANY_TYPE, null ).iterateNext().parentElement.addEventListener("click", add_country_cookie);

				document.getElementById("lang_selector").addEventListener("click", toggle_lang_switcher);

				let options = document.querySelector("#options")

				countryList.map((country: any) => {
					const option = document.createElement('p')
					option.setAttribute("data-code", country.slug);
					const text = document.createTextNode(country.name);
					option.appendChild(text);
					option.classList.add("h-c_sm4", "text-c_sm", "text-center", "pt-c_T_xs2", "border-t-c_0.6", "border-solid", "border-gray-c_dark2", "cursor-pointer");
					option.addEventListener("click", toggle_lang_switcher);
					option.setAttribute("data-value", country.name)
					options.append(option);
				});

				countryList.map((country: any) => {
					const selector = document.querySelector("#current_country");
					if(country.slug === String(ipBasedCountry))
						selector.innerHTML = country.name;
				});
			}
		}
		waitUntilBanner();
	}
})

function toggle_lang_switcher(new_lang : any) {
	const selector = document.querySelector("#lang_selector");
	selector.classList.toggle("rounded-5");
	selector.classList.toggle("rounded-5-t");

	const chevron = document.querySelector("#lang_selector_chevron");
	chevron.classList.toggle("rotate-0");
	chevron.classList.toggle("rotate-180");

	selector.nextElementSibling.classList.toggle("hidden");

	if(new_lang !== undefined){
		// menu is closed again, change selected value
		if(selector.classList.contains("rounded-5")){
			if (new_lang.target.hasAttribute("data-value")){
				const currentLangP = document.querySelector("#current_country");
				currentLangP.innerHTML = new_lang.target.getAttribute("data-value")
			}
		}
	}
}
